<template>
  <div class="index card-details">
    <!-- <van-nav-bar title="订单劵码" left-arrow @click-left="onBack" /> -->
    <van-skeleton title :row="3" :loading="loading">
      <div class="coupon">
        <!-- <div class="rest-name" @click="onRestaurant(order.restaurantId)">  -->
        <div class="rest-name"> 
            <van-icon name="shop-o" /> 
            {{order.restaurantName}}  {{order.restaurantCookingStyle}}   ¥{{order.restaurantPricerPerPerson}}/人
            <!-- <van-icon name="arrow" /> -->
        </div>
        <van-row>
          <van-col span="8">
            <van-image :src="order.productImageUrl" width="95%" height="92px"  />
          </van-col>
          <van-col span="15" offset="1">
            <div class="coupon-detail">
              <div class="coupon-name">{{order.productName}}</div>
              <div>
                  <span class="price">¥{{order.productPrice}}</span> 
                  <span class="ori-price">原价:¥{{order.productOriginPrice}}</span>
              </div>
              <div class="coupon-info" v-if="!order.verified && order.deadlineTime">
                <span>有效期至: </span> <span class="time">{{getTime(order.deadlineTime)}}</span>
              </div>
              
              <div class="coupon-info" v-if="order.verified">
                <span>消费时间:</span> 
                <span class="time">{{getTime(order.verifiedTime)}}</span>
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-skeleton>

    <div class="coupon-detailll">
      <van-skeleton title :row="4" :loading="loading">
        <div class="qr-code" :class="getOrderClassName()" @click="refreshImage">
          <van-image :src="secretUrl" />
        </div>
        <div class="num-code" :class="getOrderClassName()">动态验证码：{{ code }}</div>
        <div class="button-row">
          <!-- <span class="coupon-status" v-if="order.verified">已消费</span> -->
          <span class="coupon-status">{{getOrderStatusName()}}</span>
          <van-button v-if="isDoCancelOrder()" plain @click="onCancelOrder">申请退款</van-button>
          <div v-if="order.verified" class="coupon-info">
              <span>消费时间：</span>
              <span class="time">{{getTime(order.verifiedTime)}}</span>
          </div>
        </div>
      </van-skeleton>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { parseTime } from "../utils/index"
import { loading , clearLoading} from "../utils/loading";
import CardService from "../api/card-service";
import OrderService from "../api/order-service";
import "../css/common.scss";
import "../css/my-cards.scss";
import "../css/card-details.scss";

export default {
  components: {},
  setup() {
    const state = reactive({
      order: {},
      code: "",
      secretUrl: "",
      orderId: 0,
      loading: true
    });
    const router = useRouter();
    const { orderId } = useRoute().params;
    state.orderId = orderId;
    // const onBack = () => router.back();
    const onRestaurant = (restaurantId) => router.push("/restaurant/" + restaurantId);
    const onCancelOrder = ()=> {
      loading()
      OrderService.cancelOrder(state.orderId)
          .then(() => refreshOrder(state.orderId))
          .then(clearLoading, clearLoading)
    };
    const getTime = t => parseTime(new Date(t), "{y}-{m}-{d} {h}:{i}");
    const getOrderClassName = ()=> {
      let orderStatus = state.order.status || "";
      let verified = state.order.verified || false;
      if( orderStatus == 'REFUNDED' || orderStatus == 'REFUND') {
        return "refund";
      } else if( orderStatus == 'REFUNDING') {
        return "refund refunding";
      } else if( orderStatus == 'CANCELING' || orderStatus == 'CANCEL') {
        return "cancel";
      } else if(verified) {
        return "used";
      } 
      return "";
    } 
    const getOrderStatusName = ()=> {
        let orderStatus = state.order.status || "";
        let verified = state.order.verified || false;
        if( orderStatus == 'REFUNDING') {
          return "退款中";
        } else if( orderStatus == 'REFUNDED' || orderStatus == 'REFUND') {
          return "已退款";
        } else if( orderStatus == 'CANCELING') {
          return "取消中";
        } else if( orderStatus == 'CANCEL') {
          return "已取消";
        } else if( !verified) {
          return "未消费";
        } else if(verified) {
          return "已消费";
        }
        return "";
    }
    const isDoCancelOrder = ()=> {
        let orderStatus = state.order.status || "";
        let verified = state.order.verified || false;
        return orderStatus == 'PAID' && !verified;
    }
    const isShowOrderCodeDetails = ()=> {
        let orderStatus = state.order.status || "";
        return orderStatus != 'CANCEL';
    }

    const refreshImage = () =>
      CardService.getCardNumber(state.orderId, true).then((num) => {
        state.code = num;
        state.secretUrl = CardService.getCardSecretUrl(state.orderId);
        state.loading = false;
        createRefreshImage();
      });
    const refreshOrder = (orderId)=> OrderService.getOrder(orderId).then(order => state.order = order);
    const createRefreshImage = () => setTimeout(refreshImage, 120 * 1000);
    onMounted(() => {
        loading();
        refreshImage();
        refreshOrder(orderId).then(clearLoading, clearLoading);
    });
    return {
      ...toRefs(state),
      getTime,
      // onBack,
      onCancelOrder,
      onRestaurant,
      refreshImage,
      getOrderStatusName,
      getOrderClassName,
      isDoCancelOrder,
      isShowOrderCodeDetails
    };
  },
};
</script>
