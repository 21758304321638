import { get } from "../utils/http"
import User from "./user"


const SERVER_URL = process.env.VUE_APP_SERVER_URL;

function getCardSecretUrl(orderId , refresh) {
    let token = User.getToken() || "";
    if(refresh) {
        return SERVER_URL + "/v1/card/qrcode/" + orderId + "?refresh=true&Authorization="+ token +"&t=" + new Date().getTime()
    }
    return SERVER_URL + "/v1/card/qrcode/" + orderId + "?Authorization="+ token +"&t=" + new Date().getTime();
}

function getCardNumber(orderId , refresh) {
    const url = "/v1/card/number/" + orderId;
    const data  = {
        refresh
    }
    return get({ url  , data});
}

function getCards() {
    const url = "/v1/card/cards";
    return get({ url });
}


function getCard(cardId) {
    const url = "/v1/card/" + cardId;
    return get({ url });
}

export default {
    getCards,
    getCardSecretUrl,
    getCardNumber,
    getCard
}